import React from 'react';

import Layout from '@layouts';
import { SEO } from '@components';

const NotFoundPage = () => (
  <Layout>
    <SEO title='404: NOT FOUND' />
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
);

export default NotFoundPage;
